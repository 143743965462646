<template>
  <div>
    <b-card class="">
      <div class="overflow-hidden d-flex flex-wrap justify-content-between">
        <div class="table-col">
          <h4>{{ $t("ingredients") }}</h4>

          <div class="overflow-card custom-scrollbar">
            <b-table
              sticky-header="true"
              class="w-100 h-100 os"
              :fields="ingFields"
              :items="hammaddeler"
            >
              <template #thead-top>
                <b-tr>
                  <b-th class="oss"
                    ><span class="sr-only bg-white"></span
                  ></b-th>
                  <b-th class="oss">
                    {{ $t("ingredientTotal") }}:
                    {{
                      hammaddeler
                        .reduce(
                          (a, b) =>
                            parseFloat(!a ? 0 : a) + parseFloat(b.amount),
                          0
                        )
                        .toFixed(1)
                    }}<span class="sr-only bg-white"></span
                  ></b-th>
                  <b-th class="border-0 oss"
                    >{{
                      (
                        hammaddeler.reduce(
                          (a, b) => a + b.fiyat * b.amount,
                          0
                        ) / 1000
                      ).toFixed(getUserData().pricedecimal)
                    }}
                    {{ getUserData().currency
                    }}<span class="sr-only bg-white"></span
                  ></b-th>
                </b-tr>
              </template>

              <template #cell(ingredientname)="{ item }">
                <span class="text-small">
                  {{ item.ingredientname }}
                </span>
              </template>
              <template #cell(amount)="{ item }">
                <b-form-input
                  v-model="item.amount"
                  type="number"
                  min="1"
                  step="0.01"
                  style="text-align: end; max-width: 150px; width: 150px"
                />
              </template>
              <template #cell(fiyat)="{ item }">
                <small
                  >{{ item.fiyat.toFixed(getUserData().pricedecimal) }}
                  {{ getUserData().currency }}
                </small>
              </template>
            </b-table>
          </div>
        </div>
        <div class="my-lg-0 my-2 centerTable" cols="12" lg="2">
          <div class="d-flex justify-content-between align-items-center">
            <b-button
              variant="primary"
              :disabled="!hammaddeler"
              class="d-flex"
              @click="verify"
            >
              <feather-icon icon="CheckIcon"></feather-icon>
              {{ $t("calculate") }}
            </b-button>
          </div>
        </div>
        <div class="table-col" cols="12" lg="5">
          <h4>{{ $t("nutrients") }}</h4>
          <div class="overflow-card custom-scrollbar">
            <b-table
              class="w-100 os"
              :fields="nutFields"
              :items="besinmaddeleri"
              sticky-header="true"
            >
              <template #cell(nutrientname)="{ item }">
                <span class="text-small">
                  {{ item.nutrientname }}
                </span>
              </template>
              <template #cell(birim)="{ item }">
                <small>{{ item.birim }}</small>
              </template>
              <template #cell(deger)="{ item }">
                <small>{{
                  item.deger.toFixed(getUserData().nutrientdecimal)
                }}</small>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BTr,
  BTh,
} from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormInput,
    BTr,
    BTh,
  },
  data() {
    return {
      getUserData: getUserData,
      ingFields: [
        {
          key: "ingredientname",
          label: this.$t("ingredientName"),
          sortable: true,
          thClass: "stop",
        },
        {
          key: "amount",
          label: this.$t("amount") + " (Kg)",
          sortable: true,
          thClass: "stop",
        },
        {
          key: "fiyat",
          label: this.$t("price"),
          sortable: true,
          formatter: (val) => `${val / 1000} ${getUserData().currency}`,
          tdClass: "text-right mw-300",
          thClass: "stop",
        },
      ],
      nutFields: [
        {
          key: "nutrientname",
          label: this.$t("nutrientName"),
          sortable: true,
        },
        {
          key: "birim",
          label: this.$t("unit"),
          sortable: true,
        },
        {
          key: "deger",
          label: this.$t("value"),
          sortable: true,
          tdClass: "text-right mw-300",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      analyzes: (state) => state.analyzesModule.analyzes,
      selectedPlantId: (state) => state.app.selectedPlantId,
      besinmaddeleri: (state) => state.analyzesModule.besinmaddeleri,
      hammaddeler: (state) => state.analyzesModule.hammaddeler,
    }),
  },
  mounted() {
    this.fetchAnalyzes(this.selectedPlantId);
  },

  methods: {
    ...mapActions({
      fetchAnalyzes: "analyzesModule/fetchAnalyzes",
      calculateAnalyzes: "analyzesModule/calculateAnalyzes",
    }),
    verify() {
      const changedItems = this.hammaddeler
        .filter((item) => item.amount !== 0)
        .map((x) => ({
          id: x.id,
          deger: x.amount,
        }));
      this.calculateAnalyzes({
        values: changedItems,
        plantid: this.selectedPlantId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-card {
  overflow-y: auto;
  height: calc(100vh - 250px);
  border-radius: 0px 0 10px 10px;
  border-top: 0;
  padding: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.centerTable {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 1280px) {
    width: 10%;
  }
}

.table-col {
  @media screen and (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    width: 44%;
  }
  width: 39%;
}
.text-small {
  font-size: 0.9rem;
  font-weight: 900;
}
// custom scrollbar
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  padding: 0;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 12px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #817e7e;
  border-radius: 12px;
  transition: 300ms ease-in-out all;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #afafaf;
}
</style>

<style>
.os {
  overflow-y: auto;
  max-height: 100%;
  margin-bottom: 0;
}
.stop {
  position: sticky !important;
  top: 38px !important;
  z-index: 3 !important;
}

.oss {
  position: sticky;
  top: 0px !important;
  z-index: 4 !important;
}
</style>
