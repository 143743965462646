var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{},[_c('div',{staticClass:"overflow-hidden d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"table-col"},[_c('h4',[_vm._v(_vm._s(_vm.$t("ingredients")))]),_c('div',{staticClass:"overflow-card custom-scrollbar"},[_c('b-table',{staticClass:"w-100 h-100 os",attrs:{"sticky-header":"true","fields":_vm.ingFields,"items":_vm.hammaddeler},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{staticClass:"oss"},[_c('span',{staticClass:"sr-only bg-white"})]),_c('b-th',{staticClass:"oss"},[_vm._v(" "+_vm._s(_vm.$t("ingredientTotal"))+": "+_vm._s(_vm.hammaddeler .reduce( function (a, b) { return parseFloat(!a ? 0 : a) + parseFloat(b.amount); }, 0 ) .toFixed(1))),_c('span',{staticClass:"sr-only bg-white"})]),_c('b-th',{staticClass:"border-0 oss"},[_vm._v(_vm._s(( _vm.hammaddeler.reduce( function (a, b) { return a + b.fiyat * b.amount; }, 0 ) / 1000 ).toFixed(_vm.getUserData().pricedecimal))+" "+_vm._s(_vm.getUserData().currency)),_c('span',{staticClass:"sr-only bg-white"})])],1)]},proxy:true},{key:"cell(ingredientname)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small"},[_vm._v(" "+_vm._s(item.ingredientname)+" ")])]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{staticStyle:{"text-align":"end","max-width":"150px","width":"150px"},attrs:{"type":"number","min":"1","step":"0.01"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"cell(fiyat)",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.fiyat.toFixed(_vm.getUserData().pricedecimal))+" "+_vm._s(_vm.getUserData().currency)+" ")])]}}])})],1)]),_c('div',{staticClass:"my-lg-0 my-2 centerTable",attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"d-flex",attrs:{"variant":"primary","disabled":!_vm.hammaddeler},on:{"click":_vm.verify}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" "+_vm._s(_vm.$t("calculate"))+" ")],1)],1)]),_c('div',{staticClass:"table-col",attrs:{"cols":"12","lg":"5"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("nutrients")))]),_c('div',{staticClass:"overflow-card custom-scrollbar"},[_c('b-table',{staticClass:"w-100 os",attrs:{"fields":_vm.nutFields,"items":_vm.besinmaddeleri,"sticky-header":"true"},scopedSlots:_vm._u([{key:"cell(nutrientname)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small"},[_vm._v(" "+_vm._s(item.nutrientname)+" ")])]}},{key:"cell(birim)",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.birim))])]}},{key:"cell(deger)",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.deger.toFixed(_vm.getUserData().nutrientdecimal)))])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }